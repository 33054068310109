import React, {  } from 'react'
import { Pipeline } from "src/assembled"
import { Footer } from 'src/components'
import { SEO } from "src/components/utils"


export default function PipelinePage({ location }) {
    // seo
    const seo = <SEO
    title="Pipeline"
    description="Introduce pipelines of deargen. There are pipelines that are collaborating with leading pharmaceutical companies, and there is also a 4D pipelines(Deargen Driven Drug Development) which Deargen's own progress." 
    pathname={location.pathname} />


    // render
    const render = <main role="main">
        <Pipeline />
    </main>


    return <>
        {seo}
        {render}
        <Footer location={location} />
    </>
}